@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  font-family: "Quicksand";
  color: #333;
  /* scroll-behavior: smooth; */
}

.Nav-Item:hover
{
  color: #4e789f;
  border-bottom: #4e789f;
  border-style: solid;
  padding-bottom: 0.1em;
}

.HomePageCover
{
  background-image: url('./Components/Assets/bg-2.jpg');
  background-color: #333;
  background-size: cover;
  background-repeat: no-repeat;
}

.fullScreen
{
  max-height: 100dvh;
}

/* ---------------------- animations ---------------------------------------------- */

.slideFromLeft
{
  transform: translateX(-8rem);
  opacity: 0;
  transition: 500ms ;
}

.slideFromLeft.show
{
  transform: translateX(0);
  opacity: 1;
  transition-delay: 75ms;
}

.slideUp
{
  transform: translateY(5rem);
  opacity: 0;
  transition: 500ms ;
}

.slideUp.show
{
  transform: translateX(0);
  opacity: 1;
  transition-delay: 75ms;
}








body {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0.2;
  }
  100% {
      opacity: 1;
  }
}